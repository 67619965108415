import React, { useEffect } from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import PageLayout from "../../layouts/Page";
import Hero from "../../components/Hero";
import Card from "../../components/Card";

import PointCollaborationImage from '../../assets/point-collaboration.svg';
import PointRealEnvironmentImage from '../../assets/point-real-environment.svg';
import PointImmediateResultsImage from '../../assets/point-immediate-results.svg';

import HubspotForm from "../../components/HubspotForm";

import './contact.scss';

const SchedulePage = props => {
  const { location } = props;
  const mixpanel = useMixpanel();

  useEffect(() => {
    mixpanel.register({ origin: 'Website - Contact sales' });
    mixpanel.track('Contact Sales Viewed');
  }, [mixpanel]);

  return (
    <div className="SchedulePage">
      <PageLayout 
        location={location} title="Contact" 
        description="Let us know how to get in touch with you. One of our experts will reach out to answer your questions as soon as possible."
      >

        <Hero>
          <Hero.Title>Let’s discuss your project</Hero.Title>
          <Hero.Text>Let us know how to get in touch with you. One of our experts will reach out to answer your questions as soon as possible.</Hero.Text>
        </Hero>

        <div className="ContainerLegacy">
          <div className="ContactContentGrid">
            <ul className="ScheduleFeatures">
              <li className="ScheduleFeature">
                <PointRealEnvironmentImage className="ScheduleFeatureIcon" />
                <div className="ScheduleFeatureContent">
                  <h3 className="ScheduleFeaturesTitle">Realistic development environments</h3>
                  <p className="ScheduleFeaturesText">Your development container reuses the same variables, secrets, sidecars, volumes, etc as your original deployment. This eliminates integration issues.</p>
                </div>
              </li>
              <li className="ScheduleFeature">
                <PointImmediateResultsImage className="ScheduleFeatureIcon" />
                <div className="ScheduleFeatureContent">
                  <h3 className="ScheduleFeaturesTitle">Replicability</h3>
                  <p className="ScheduleFeaturesText">Eliminate the need to install your dependencies locally, everything is pre-configured in your development image.</p>
                </div>
              </li>
              <li className="ScheduleFeature">
                <PointCollaborationImage className="ScheduleFeatureIcon" />
                <div className="ScheduleFeatureContent">
                  <h3 className="ScheduleFeaturesTitle">Previews for everyone</h3>
                  <p className="ScheduleFeaturesText">Okteto is the easiest and fastest way to get shareable preview environments with each pull request to keep your entire organization in the loop and collaborating.</p>
                </div>
              </li>
            </ul>
            <Card theme="Light" paddingSize="Double">
              <Card.Title className="text-center">Contact us</Card.Title>
              <HubspotForm />
            </Card>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default SchedulePage;
