import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Card.scss';

function Card(props) {
  const theme = props.theme ? `CardTheme${props.theme}` : '';
  const paddingSize = props.paddingSize
    ? `CardPadding${props.paddingSize}`
    : '';
  const shadowLevel = props.shadowLevel
    ? `CardShadowLevel${props.shadowLevel}`
    : '';
  const clickable = props.clickable ? `CardClickable` : '';
  const className = props.className;

  return (
    <div
      {...props}
      className={classnames(
        'Card',
        theme,
        paddingSize,
        shadowLevel,
        clickable,
        className
      )}
    >
      {props.children}
    </div>
  );
}

Card.propTypes = {
  theme: PropTypes.oneOf(['Light', 'Green', 'Blue', 'LightBlue']),
  paddingSize: PropTypes.oneOf(['Normal', 'Double', 'Half']),
  shadowLevel: PropTypes.oneOf(['Normal', 'Low']),
};

function CardTitle(props) {
  return (
    <h3 className={classnames('CardTitle', props.className)}>
      {props.children}
    </h3>
  );
}

function CardImage(props) {
  return <div className="CardImage">{props.children}</div>;
}

function CardContent(props) {
  return <div className="CardContent">{props.children}</div>;
}

function CardCTA(props) {
  return <div className="CardCTA">{props.children}</div>;
}

function CardIcon(props) {
  return (
    <div className="CardIconContainer">
      <div className="CardIcon CardIconCircle">{props.children}</div>
    </div>
  );
}

export default Object.assign(Card, {
  Title: CardTitle,
  Content: CardContent,
  CTA: CardCTA,
  Image: CardImage,
  Icon: CardIcon,
});
